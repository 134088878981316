<template>
	<div id="app" v-cloak>
		<router-view />

		<div class="totalBox_view" v-show='false'>
			<vue-canvas-poster borderRadius="50" :painting="painting" @success="success" @fail="fail">
			</vue-canvas-poster>
		</div>

		<img :src="require('@/assets/image/sclsk.png')" alt="" class="app_rightpc" v-if="isPc&&iconkeSelect" @click="heftBut()"/>
	</div>
</template>
<script>
	import {
		mapState
	} from "vuex";
	import {
		Toast
	} from "vant";
	export default {
		name: "app",
		data() {
			return {
				painting:null,
				iconke:['/wa'],
				iconkeSelect:true,
				baseUrl:window.location.protocol+'//'+window.location.host+'/wa',
			};
		},
		mounted() {},
		created() {
			this.getWhatApp() 
			if(this.getQueryString('c')!=null && this.getQueryString('c') != undefined && this.getQueryString('c')!=''){
				localStorage.setItem('c',this.getQueryString('c'))
			}
		},
		watch: {
			$route: {
				 handler(to, from) {
					  console.log('to：：',to,from)
					 if( this.iconke.includes(to.path)){
					   this.iconkeSelect = false
					 }else{
					   this.iconkeSelect = true
					 }
				}
			}
		},
		computed: {
			...mapState(["isPc", "viewShow"]),
			isPc() {
				return this.$store.state.isPc;
			},
		},
		methods: {
			getQueryString(name) {
				return (
					decodeURIComponent(
					(new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
						location.href
					) || [, ""])[1].replace(/\+/g, "%20")
					) || null
				);
			},
			heftBut(){
				window.open('https://chineserd.com.hk/Course/recite', "_blank");
			},
			success(src) {
				// console.log(src)
				this.dataUrl = src;
				sessionStorage.setItem('whatAppEwm', src);
			},
			fail(err) {
				console.log('fail', err)
			},
			getWhatApp() {
				this.painting = {
					width: '100px',
					height: '100px',
					background: '#ffffff',
					views: [{
						type: 'qrcode',
						content: this.baseUrl,
						css: {
							top: '10px',
							left: '10px',
							right: '10px',
							bottom: '10px',
							color: '#000',
							width: '80px',
							height: '80px'
						}
					}]
				}
				
				
				// this.$axios
				// 	.get(this.$api.getWhatApp)
				// 	.then(res => {
				// 		console.log(res.data)
				// 		if (res.data.code == 0) {
				// 			sessionStorage.setItem('whatApp', res.data.data);
							

				// 		} else {
				// 			console.log('失败')
				// 		}
				// 	})
				// 	.catch((res) => {
				// 		console.log('失败', res)
				// 	})
			}
		},
	};
</script>
<style lang="scss" scoped>
	.app_rightpc {
		width: 220px;
		position: fixed;
		right: 5%;
		bottom: 10%;
		margin: auto;
		cursor: pointer;
	}
</style>

<style lang="scss">
	@import "@/assets/css/reset.scss";

	[v-cloak] {
		display: none !important;
	}

	body,
	html {
		// width: 100vw;
		// height: 100%;
	}

	html::-webkit-scrollbar {
		display: none !important;
	}
	body::-webkit-scrollbar {
		display: none !important;
	}
	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		width: 100%;
		height: 100%;
		position: relative;
		padding-bottom: constant(safe-area-inset-bottom);
		/*兼容 IOS<11.2*/
		padding-bottom: env(safe-area-inset-bottom);
		/*兼容 IOS>11.2*/
	}

	#app::-webkit-scrollbar,
	.app::-webkit-scrollbar {
		display: none;
	}

	[v-cloak] {
		display: none;
	}
</style>
