// const host = "https://speech.chineserd.hk"; //正式環境
let hostShow = true;

const host = hostShow?'https://speech.chineserd.hk':"https://oms.admin.zhixinlive.com"; //測試環境

const hostUpload = "https://oms.chineserd.com";//上传专用



const _api = {
	// 获取验证码
	sendSmsForSecondCup:host+'/appapi/sso/message/sendSmsForSecondCup',
	// 获取区域
  getHkSchoolRegion:host+'/api/statistics/HkSchool/getHkSchoolRegion',
  // 獲取索引
  getHkSchool:host+'/api/statistics/HkSchool/getHkSchool',
  //提交表單
  fromSave: host + "/api/statistics/competition/save", 
  
  getCredential: hostUpload + "/api/student/api/getCredential", //獲取上傳key
  // burialPointPage: host + "/api/student/studentBurialPointData/pageDataAdd", //页面埋点数据  post
  // burialPointEvent: host + "/api/student/studentBurialPointData/eventDataAdd", //事件埋点数据 post
  // sendSmsForCupCode: host + "/appapi/sso/message/sendSmsForSecondCup", //獲取驗證碼
  getPersonInfo:host+"/api/statistics/competition/getPersonInfo",//查詢入围
  update:host+'/api/statistics/competition/update',//提交
  promotionStudentList:host+'/api/statistics/competition/promotionStudentList',//入圍名單
  getWhatApp:'/api/statistics/competition/getWhatsAppDetail',//获取联系方式
};

export const api = _api;
